@import "../../../../styles/variables";

.root {
  width: 100%;
  height: calc(100% - 67px);
}

.marker {
  position: absolute;
  top: -58px;
  left: -18px;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  background-color: $status-default;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    letter-spacing: 0.481894px;
  }

  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: $status-default;
    transform: rotate(45deg);
    bottom: -3px;
    position: absolute;
  }

  &::after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-color: #fff;
    transform: rotate(45deg);
    bottom: -22px;
    left: 11px;
    position: absolute;
    border: $status-default 3px solid;
    border-radius: 14px;
    box-sizing: border-box;
  }
}
