@import "../../../../styles/variables";

.root {
  position: relative;
  background-color: #fff;
  border: 1px solid $primary-low;
  box-sizing: border-box;
  border-radius: $border-radius;
  margin: 3px 0;
  padding: 8px 8px 12px;
}

.checkbox {
  margin-top: 0;
  margin-bottom: 7px;
  display: flex;
  align-items: center;

  input {
    margin-left: 0;
  }

  label {
    font-size: 15px;
    color: #000000;
  }
}

.address {
  font-size: 13px;
  line-height: 16px;
  color: $primary-l;
  margin: 0;
}

.index {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $status-default;
  position: absolute;
  top: 9px;
  right: 6px;

  color: #ffffff;
}

.delivery_time {
  font-size: 13px;
  line-height: 16px;
  color: $primary-l;
  position: absolute;
  right: 6px;
  bottom: 10px;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
}
