.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 210px;
    padding: 24px 11px 26px 14px;
    transition: margin-left 250ms ease-in-out;
    transition-delay: 250ms;

    &.--sidebar-extended {
      margin-left: 70px;
    }

    &.--fullsize {
      padding: 0;
    }

    &.--full-width {
      margin: 0;
    }

    &.--center {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &.--column {
      flex-direction: column;
    }
  }
}
