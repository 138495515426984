.root {
  display: inline-flex;
  align-items: center;

  height: 22px;

  border-radius: 46px;
  font-size: 14px;
  line-height: 1;

  padding: 2px 8px 2px 1.5px;
  box-sizing: border-box;

  &.transfer_requested {
    background-color: #d2defc;
    color: #132143;

    path {
      fill: #132143;
    }
  }

  &.missing_transfer {
    background-color: #f9dde5;
    color: #000000;

    path {
      fill: #000000;
    }
  }

  &.unresponsive {
    background-color: #f9e3d7;
    color: #652c1b;

    path {
      fill: #652c1b;
    }
  }
}

.icon {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
  margin-right: 4px;
  padding: 1.5px;
  box-sizing: border-box;
}
