.DeliveryPlanner__Sort {
  .Select {
    &__control {
      border: none;
      box-sizing: border-box;
      background-color: transparent;
      // min-width: 160px;

      &--is-focused {
        box-shadow: none;
      }

      &--menu {
      }
    }

    &__placeholder {
      color: #424242;
    }

    &__menu {
      min-width: 160px;
      right: 0;
    }
  }
}
