@import "../../styles/variables";

.datetime_picker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: $primary-l;

  border: 1px solid $primary-mid;
  box-sizing: border-box;
  border-radius: 8px;
  height: 38px;
  max-width: 120px;
  padding-left: 6px;

  margin: 0 5px;
}

.calendar {
  min-width: 327px;
}
