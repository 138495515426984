@import "../../../../styles/variables";

.root {
  position: relative;
}

.options_menu {
  position: absolute;
  top: 38px;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
  border: 1px $primary-mid solid;
  border-radius: $border-radius;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.option {
  padding: 4px 8px;
  cursor: pointer;

  p {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $primary-l;
    p {
      color: #fff;
    }
  }
}
