.root {
  min-width: 642px;
}

.actions {
  display: flex;
  justify-content: flex-end;

  margin-top: 50px;

  button {
    & + button {
      margin-left: 15px;
    }

    &:last-child {
      min-width: 300px;
    }
  }
}
