@import "../../styles/variables";

.root {
  position: fixed;
  width: 210px;
  max-width: 210px;
  left: 0;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow: auto;

  padding: 20px 24px;

  display: flex;
  flex-direction: column;

  background-color: $gray-6;
  box-shadow: 1px 0px 0px #eeeeee;
  overflow: hidden;

  transition: all 250ms ease-in-out;
  transition-delay: 250ms;

  &.--narrow {
    padding: 20px 12px;
    max-width: 69px;

    .logo {
      margin-left: 10px;
    }

    .text {
      visibility: hidden;
    }

    .menu_item {
      > a {
        padding: 0 11px;
        max-width: 45px;
      }
    }

    .submenu {
      max-height: 0;
    }

    .user {
      height: 45px;
      width: 45px;
      padding: 4px;
      justify-content: center;
      margin: 0;

      transition: all 250ms ease-in-out;
      transition-delay: 500ms;

      &__details {
        display: none;
        max-width: 0;
        overflow: hidden;
      }
    }
  }
}

.logo {
  width: 20px;
  transition: all 250ms ease-in-out;
  transition-delay: 250ms;
}

.nav {
  padding: 30px 0;
}

.nav_padding {
  flex-grow: 1;
  flex-basis: 0;
  max-height: 90px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu_item {
  width: 100%;

  > a {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 35px;
    color: $primary;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    transition: all 250ms ease-in-out;

    &.active {
      background-color: $status-default;

      color: $white;

      path[fill="black"],
      path[fill="#222222"] {
        fill: $white;
      }

      path[stroke="black"],
      path[stroke="#222222"] {
        stroke: $white;
      }
    }
  }

  & + & {
    margin-top: 10px;
  }

  &__icon {
    margin-right: 12px;
  }
}

.submenu {
  margin: 0 0 0 36px;
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 200px;

  transition: max-height 250ms ease-out;

  &__item {
    padding: 4px 0;
    white-space: nowrap;

    &:first-child {
      margin-top: 10px;
    }

    a {
      display: inline-flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: $primary-l;
      text-decoration: none;

      &:before {
        content: "";
        width: 8px;
        height: 8px;
        background: #c4c4c4;
        border-radius: 8px;
        margin-right: 12px;
      }

      &.--active {
        color: #000000;

        &::before {
          background: $primary;
        }
      }
    }
  }

  &.--hidden {
    max-height: 0;
  }
}

.user_wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-right: -16px;
  margin-left: -16px;
  padding: 4px 4px 4px 16px;
  border-radius: 48px;
  cursor: pointer;

  transition: all 250ms ease-in-out;
  transition-delay: 500ms;

  &:hover {
    background-color: rgba($color: $primary-low, $alpha: 0.5);
  }

  &:active {
    background-color: $primary-low;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100px;
  }

  &__name {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
  }

  &__email {
    font-size: 12px;
    line-height: 14px;

    color: #000000;
  }

  &__avatar {
    object-fit: cover;
    height: 40px !important;
    width: 40px !important;
    margin-right: 0 !important;
    border-radius: 40px;
  }
}
