@import "../../styles/variables";

.Select {
  &__control {
    box-sizing: border-box;
    border-color: $primary-low !important;
    border-radius: 3px !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__dropdown-indicator {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    svg path {
      fill: #797979;
    }
  }

  &.--round {
    .Select__control {
      border-radius: $border-radius !important;
    }
  }

  &.--sm {
    .Select__control {
      min-height: $height-sm !important;
    }
  }

  &.--md {
    .Select__control {
      min-height: $height-md !important;
    }
  }

  &.--lg {
    .Select__control {
      min-height: $height-lg !important;
    }
  }
}
