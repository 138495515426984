@import "../../styles/variables";

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title_wrapper {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 28px;
}

.subtitle {
  color: $primary-l;
  margin-left: 70px;
}

.buttons {
  display: flex;

  .complete_btn {
    margin-right: 26px;
  }
}

.content {
  display: flex;
  margin-top: 34px;
}

.main_content {
  flex-grow: 1;
  padding-right: 10px;
}

.drugs {
  flex-basis: 320px;
  flex-grow: 0;
}

.info {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
  }

  &.--borderless {
    border-bottom: none;
  }
}

.fullname {
  margin-bottom: 20px;
}

.identification_card {
  position: absolute;
  top: 26px;
  right: 0;
}

.insurance {
  display: flex;
  align-items: center;
  height: 48px;

  &__logo {
    max-width: 56px;
    margin-right: 20px;
  }

  &__edit_wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding: 0 5px;
  }

  &__edit {
    color: $primary-l;
  }
}

.drug {
  position: relative;
  padding-bottom: 14px;
  border-bottom: 1px solid #eeeeee;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.otc {
  position: relative;
  padding-bottom: 14px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__name {
    margin-bottom: 7px;
  }
}

.confirm_title {
  margin-bottom: 12px;
}
