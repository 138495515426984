@import "../../styles/variables";

.root {
  margin: 12px 6px;
  width: calc(100% - 12px);
  box-sizing: border-box;
}

.table {
  display: grid;
  gap: 10px 8px;
  margin: 0 15px;
}

.head {
  margin-bottom: 20px;
}

.header_cell {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.cell {
  margin-top: 3px;

  &.--center {
    justify-self: center;
  }

  &.--right {
    justify-self: right;
  }
}

.border {
  grid-column-start: 1;
  grid-column-end: -1;

  border-bottom: 1px #eeeeee solid;
}

.sort_button {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 5px 12px;
  transition: all 250ms ease-in-out;

  &.--active {
    background-image: url("./icons/arrow_down.svg");
  }

  &.--asc {
  }

  &.--desc {
    transform: rotate(180deg);
  }
}

.loader,
.empty {
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.load_more {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
