@import "../../../../styles/variables";

.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
}

.action_btn {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: $primary;
  transition: all 250ms ease-in-out;

  & > i {
    margin-right: 6px;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba($color: $primary, $alpha: 0.7);
  }

  &.--delete {
    &:hover:not(:disabled) {
      color: $status-critical;
    }
  }

  &.--save {
    &:hover:not(:disabled) {
      color: $status-default;
    }
  }

  &.--send {
    &:hover:not(:disabled) {
      color: $green-3;
    }
  }
}
