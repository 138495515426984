@import "../../styles/variables";

.root {
  position: relative;
  padding: 0 30px;
  box-sizing: border-box;
  outline: none;

  border: none;
  border-radius: $border-radius;

  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  cursor: pointer;

  text-decoration: none;

  &:disabled {
    cursor: not-allowed;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: $border-radius;
      background-color: rgba($color: #fff, $alpha: 0.6);
    }
  }

  &.--loading {
    background-color: $primary-l;
  }

  &.--green {
    background-color: $green-1;
  }

  &.--primary {
    background-color: $primary;

    &:disabled {
      background-color: $primary-l;
      cursor: not-allowed;
    }

    &.--loading {
      background-color: $primary-l;
    }
  }

  &.--gray {
    background-color: $gray-4;
  }

  &.--blue {
    background-color: $status-default;
  }

  &.--outlined {
    background-color: transparent;
    border: 1px solid #777777;
    color: #777777;
  }

  &.--critical {
    background-color: $status-critical;
  }

  &.--lg {
    height: 45px;
  }

  &.--md {
    height: 40px;
  }

  &.--sm {
    height: 32px;
  }

  &.--loading {
    cursor: progress;
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: #fff, $alpha: 0.5);

  > div {
    transform: scale(0.7);
  }
}
