@import "../../styles/variables";

.root {
  display: grid;
  grid-template-columns: 320px 320px auto;
  grid-auto-rows: 1fr;
  gap: 9px;
  flex-grow: 1;
  max-height: calc(100vh - 20px);
  padding: 16px 8px 4px;
}

.panel {
  background: #f9f9fd;
  border-radius: $border-radius;
  max-height: 100%;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 67px;
    border-radius: $border-radius $border-radius 0 0;

    background-color: #ececf4;
    padding: 0 10px;

    &.--dark {
      background-color: $primary;
      color: #fff;
    }
  }

  &__inbox_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #222222;
    margin-left: 10px;
  }

  &__vehicle {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 4px;

    img {
      margin-right: 4px;
    }
  }

  &__endpoints {
    margin: 0;
    white-space: nowrap;
    font-size: 12px;
    line-height: 14.32px;
    max-width: 245px;
    overflow: hidden;

    span {
      color: #fff;
      font-size: 12px;
    }
  }

  &__change_vehicle {
    text-decoration: underline;
    cursor: pointer;
  }

  &__filters {
    background: #f9f9fd;
    box-shadow: 0px -1px 0px #dbdbdb, 0px 1px 0px #dbdbdb;
    min-height: 60px;
    position: relative;

    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__start {
    display: flex;
    align-items: center;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
  }

  &__unassign {
    padding: 0 15px !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    background-color: #fe7062 !important;
    border-radius: $height-sm !important;

    &:disabled {
      background-color: $primary-low !important;

      &::before {
        display: none;
      }
    }
  }

  &__content {
    padding: 5px 10px;
    height: calc(100% - 187px);
    overflow: auto;
    box-sizing: border-box;

    &.--assigned {
      height: calc(100% - 302px);
    }
  }

  &__bottom {
    display: flex;
    height: 70px;
    padding: 0 11px 10px;

    > button {
      flex-grow: 1;
    }
  }

  &__assigned_buttom {
    background: #ffffff;
    border: 1px solid #ececf4;
    box-sizing: border-box;
    border-radius: 8px 8px 0px 0px;

    display: flex;
    flex-direction: column;
    height: 175px;
    padding: 16px 11px 10px;
  }

  &__assigned_summary {
    display: flex;
    justify-content: space-between;
    padding: 2px 2px 12px;
    border-bottom: 1px solid #eeeeee;
  }

  &__deliveries_counter {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: $primary;
  }

  &__deliveries_end_time {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;

    img {
      margin-right: 4px;
      width: 13px;
      height: 13px;
    }

    span {
      color: $primary-l;
      margin-right: 4px;
    }
  }

  &__assigned_stats {
    display: flex;
    margin-top: 12px;
  }

  &__assigned_stat {
    display: grid;
    flex-grow: 1;
    grid-template-columns: 16px auto;
    gap: 0 6px;
    grid-template-areas:
      "icon metric"
      ". disc";
    margin-bottom: 16px;

    img {
      grid-area: icon;
    }

    span:nth-child(2) {
      grid-area: metric;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      display: flex;
      align-items: center;

      color: $primary;
    }

    span:last-child {
      grid-area: disc;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      display: flex;
      align-items: center;

      color: $primary-l;
    }
  }

  &__calc {
    display: flex;
    margin-top: 15px;
    margin-bottom: 8px;

    > button {
      flex-grow: 1;
    }
  }
}

.datetime_picker_wrapper {
  position: relative;

  img {
    position: absolute;
    top: 11.5px;
    left: 12px;
  }
}
