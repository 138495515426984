@import "../../../../styles/variables";

.form {
  max-height: 0;

  box-sizing: border-box;
  overflow: hidden;

  transition: all 250ms ease-in-out;

  &.--expanded {
    max-height: 300px;
    overflow: visible;
  }

  &__content {
    display: grid;

    grid-template-columns: 70px 220px;
    grid-template-rows: repeat(4, 40px);
    grid-gap: 10px 5px;

    padding: 26px 10px 16px 15px;

    .label {
      display: flex;
      align-items: center;

      font-size: 14px;
      line-height: 17px;
      color: $primary-l;
    }

    input {
      border-radius: 8px !important;
    }
  }
}

.option {
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.actions {
  display: flex;
  grid-column-start: 2;

  button + button {
    margin-left: 6px;
  }
}
