$primary: #222222;
$primary-mid: #acacac;
$primary-l: #666666;
$primary-low: #dbdbdb;

$black: #000;
$white: #fff;

$error: #ff3b24;

$iris-60: #a5a6f6;
$iris-100: #5d5fef;

$green-1: #219653;
$green-2: #27ae60;
$green-3: #6fcf97;

$status-default: #0072b3;
$status-critical: #e0221d;

$fuschia-80: #f178b6;

$blue-2: #023e61;

$gray-2: #dde2e5;
$gray-4: #bdbdbd;
$gray-6: #f8f6f3;

$border-radius: 8px;

$height-lg: 45px;
$height-md: 40px;
$height-sm: 35px;
