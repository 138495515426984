@import "../../styles/variables";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7fcff;
  border: 1px dashed $status-default;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 256px;
  min-height: 183px;
  padding: 12px;
  margin-top: 23px;
  cursor: pointer;

  input {
    display: none;
  }
}

.label {
  font-size: 13px;
  line-height: 14px;
  color: $primary-l;
  text-align: center;

  & + & {
    margin-top: 4px;
  }
}

.uploads {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  padding: 8px 0 4px;
}

.thumb {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 120px;
  height: 113px;
  box-sizing: border-box;
  background-color: $status-default;
  border-radius: 9px;

  & + & {
    margin-left: 26px;
  }

  &.--uploading {
    background-color: rgba($color: $status-default, $alpha: 0.6);
    border: 1px $status-default dashed;

    .close {
      display: none;
    }

    .image_container {
      background-color: rgba($color: $blue-2, $alpha: 0.6);
    }
  }
}

.close {
  position: absolute;
  top: 2.25px;
  right: 2.25px;
  z-index: 10;
  width: 20px;
  height: 20px;

  img {
    position: absolute;
    z-index: 20;
  }

  &__x {
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 10;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    background-color: #fff;
  }
}

.image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: $blue-2;
  margin-bottom: 11px;
}

.filename {
  font-size: 11px;
  line-height: 12px;
  text-align: center;

  color: #ffffff;
  margin: 4px;
}

.spinner {
  position: absolute;
}
