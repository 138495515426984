@import "../../../../styles/variables";

.DeliveryPlanner__Filter {
  .Select {
    &__control {
      border: 1px solid #cccccc;
      box-sizing: border-box;
      border-radius: 8px !important;
      min-width: 160px;
    }

    &__placeholder {
      color: #424242;
    }

    &__menu {
      min-width: 170px;
      right: -5px;
    }

    &__group-heading {
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $primary-l;
    }

    &__option {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $primary;

      &::before {
        content: "";
        display: block;
        border: 1px solid $gray-2;
        box-sizing: border-box;
        border-radius: 3px;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      &--is-focused {
        background-color: #fff;
      }

      &--is-selected {
        background-color: #fff;

        &::before {
          background-color: $green-2;
          background-image: url("../../icons/checkmark.svg");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
